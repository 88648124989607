<script setup lang="ts">
import type { Promotion } from "@/types";

const { t } = useT();
const { open } = useTaoModals();

const props = defineProps<{
	image: string;
	subTitle: string;
	title: string;
	coins: number;
	entries: number;
	type?: Promotion["type"];
}>();

const emit = defineEmits<{ (event: "click"): void }>();

const handleClick = () => {
	emit("click");
};

const handleHowItWorksModal = () => {
	open("LazyOModalPromoHowItWorks", { type: props.type });
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 80,
			height: 80,
			src: image
		}"
		width="330px"
		height="310px"
		class="card-inviteQualification"
		@click="handleClick"
	>
		<template #default>
			<span class="btn-info" @click.stop="handleHowItWorksModal">
				<ASvg name="16/info" class="icon" />
			</span>
		</template>
		<template #top>
			<AText class="title" :modifiers="['semibold', 'uppercase', 'center']">
				{{ title }}
			</AText>

			<AText class="sub-title" variant="ternopil" :modifiers="['semibold', 'uppercase']" as="em">
				{{ subTitle }}
			</AText>
		</template>
		<template #bottom>
			<div class="box">
				<AText
					v-if="coins"
					variant="tunis"
					data-tid="promo-invite-qa-coins"
					:modifiers="['bold', 'uppercase']"
					:class="['count', { single: !entries }]"
				>
					<ASvg name="12/coins" class="icon" />
					{{ numberFormat(coins) }}
				</AText>

				<AText
					v-if="entries"
					variant="tunis"
					data-tid="promo-invite-qa-entries"
					:modifiers="['bold', 'uppercase']"
					class="count"
				>
					<ASvg name="12/secret-coins" class="icon" />
					{{ numberFormat(entries) }}
				</AText>

				<AButton variant="primary" class="promo" data-tid="promo-invite-qa-buy">
					<AText :modifiers="['bold', 'uppercase']">
						{{ t("Qualify") }}
					</AText>
				</AButton>
			</div>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card {
	color: var(--coro);
	background: var(--galati);

	.title {
		padding: gutter(0.25) 0;
	}
}

.count {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: gutter(0.5);

	&.single {
		margin-bottom: gutter(5.5);
	}

	.icon {
		transform: translateY(-1px);
		font-size: 24px;
	}
}

.box {
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
	gap: gutter(1);

	button {
		width: 100%;
		height: 40px;
	}
}
.sub-title {
	text-shadow: 0 1px 1px var(--chile);
}

.btn-info {
	position: absolute;
	z-index: 2;
	cursor: pointer;
	top: 10px;
	left: 10px;

	.icon {
		font-size: 16px;
	}
}
</style>
